<template>
  <div
    id="modalImages"
    class="modal"
    @keydown.esc="toggleModalImages"
    tabindex="0"
    v-bind:class="{ active: modalImagesVisible }"
  >
    <div id="close" v-on:click="toggleModalImages">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="var(--green-700__digizine)"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </div>
    <h2 class="modal-heading">Bild auswählen</h2>

    <div class="modal-introText">
      <h3>
        Scrollt durch unseren Bilderpool: Die Bilder laden Euch ein, über Themen
        wie Kollektivität, Zusammenarbeit und Gruppendynamiken nachzudenken.
      </h3>
      <div>
        <p>
          Sie wurden zusammengestellt von Sara Al-Afifi, Paul Niedermayer sowie
          Abies Robinson, Hildegard Oehler und Olivia Emefiele.
        </p>
        <p>
          Sara Al-Afifi ist Grafikdesignerin, Forscherin und Illustratorin und
          lebt in Doha, Katar. Sie erforscht die kollektive Psychologie
          arabischer Gesellschaften.
        </p>
        <p>
          Niedermayer ist Künstlerin mit einem Schwerpunkt auf Fotografie,
          Bildmedien und Raumkonzepte. Im Zentrum Ihrer Arbeit stehen die
          politische Implikationen von alltäglichen sozialen Sphären.
        </p>

        <p>
          Abies Robinson, Hildegard Oehler und Olivia Emefiele sind Studentinnen
          der Gestaltung an der Uni Köln. Gemeinsam haben sie das Afrodeutsch
          Zine für vergangene und gegenwärtige Afrodeutsche Kultur gegründet.
        </p>
      </div>
    </div>

    <div class="modalImagesGrid">
      <div
        v-show="imageCollections !== null"
        v-for="(image, i) in imageCollections"
        v-bind:key="i"
        class="modal-imageContainer"
      >
        <img v-bind:src="image.thumb" />
        <div
          class="modal-image-info"
          @click="updateImage(image.url)"
        >
          <h3>{{ image.title }} {{ showYear(image.year) }}</h3>
          <p>{{ image.author }}</p>
          <div class="modal-image-sources">
            <a target="_blank " :href="image.source" v-if="image.source !== ''"
              >Quelle</a
            >
            <a
              target="_blank "
              :href="image.license"
              v-if="image.license !== ''"
              >Lizenz</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalImages",
  data() {
    return {
      showImageInfo: [],
    };
  },
  mounted() {
    this.axios.get(this.$store.state.host + 'zine/api/' + 'images').then((response) => {
        this.$store.commit("changeImages", response.data);
        this.shuffleImages(this.imageCollections);
      })
  },
  computed: {
    imageCollections() {
      return this.$store.state.imageCollections;
    },
    modalImagesVisible() {
      return this.$store.state.modalImages.visible;
    },
    imageTarget() {
      return this.$store.state.modalImages.target;
    },
  },
  methods: {
    reloadImages() {
      
    },
    toggleModalImages() {
      this.$store.commit("toggleModalImages");
    },
    updateImage(path) {
      if (this.imageTarget == 1) {
        this.$store.commit("updateImage1", path);
      } else if (this.imageTarget == 2) {
        this.$store.commit("updateImage2", path);
      }
      this.$store.commit("toggleModalImages");
    },
    shuffleImages(images) {
      for (let i = images.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [images[i], images[j]] = [images[j], images[i]];
      }
      return images;
    },
    showYear(year) {
      if (year !== "") {
        return `(${year})`;
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
#close svg {
  width: 35px;
  height: 35px;
}

#close svg:hover {
  stroke: var(--green-500__digizine);
}

#modalImages {
  padding: 45px 45px 100px 84px;
}

#modalImages h2 {
  font-weight: 500 !important;
}

.modal-introText {
  display: flex;
  padding-top: 30px;
  width: 85%;
}

.modal-introText h3 {
  font-size: 19px;
  color: var(--brown-700__digizine);
  margin-right: 50px;
}

.modal-introText div p {
  font-size: 15px;
  color: var(--gray-500__digizine);
}

.modal-introText h3,
.modal-introText div {
  max-width: 800px;
}

.modal-introText div p:first-of-type {
  margin-top: 0;
}

.modalImagesGrid {
  margin-top: 75px;
  margin-bottom: 40px;
}

.modalImagesGrid .modal-imageContainer {
  display: inline-block;
  position: relative;
  overflow-y: hidden;
  margin-top: -10px;
}

.modal-image-info {
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  bottom: 0;
  left: 0;
  width: calc(100% - 10px);
  height: 100%;
  opacity: 0;
  transition: all 0.2s ease-in;
  transform: translateY(20px);
}

.modal-image-info:hover {
  opacity: 1;
  transform: translateY(0);
}

.modal-image-info h3 {
  background: white;
  font-size: 13px !important;
  color: var(--green-700__digizine);
  padding: 8px 6px 0px 6px;
  margin-bottom: 0;
  width: 100%;
}

.modal-image-info p {
  background: white;
  font-size: 13px;
  color: var(--gray-500__digizine);
  width: 100%;
  padding: 3px 6px 0px 6px;
  margin-top: 0 !important;
  margin-bottom: 0;
}

.modal-image-sources {
  padding: 0px 6px 6px 6px;
  background: white;
  width: 100%;
  margin-bottom: 9px;
}

.modal-image-sources a {
  font-size: 13px;
  color: var(--gray-500__digizine);
}

.modal-image-sources a:first-child {
  margin-right: 8px;
}

.modalImagesGrid div img {
  height: 250px;
  width: auto !important;
  object-fit: cover;
  margin-right: 10px;
  margin-bottom: 10px;
}

/* .modalImagesGrid div img:hover {
  box-shadow: 0 0 10px var(--green-700__digizine);
} */

#modalImages.active {
  top: 0;
}
</style>
