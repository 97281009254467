import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

// import imageCollections from "./data/images.json";
import fonts from "./data/fonts.json";

// const HOST = 'http://lbh-collaboratory-cms.ddev.site/';
const HOST = 'https://dev.collaboratory-lenbachhaus.de/';


// Dummy-Layout-Daten
// import spreads from "./data/spreads.json";

const store = new Vuex.Store({
    state: {
        host: HOST ,
        id: new URLSearchParams(window.location.search).get('id'),
        magtitle: 'Untitled',
        magauthor: 'Unnamed',
        order: 0,
        // layout daten zum speichern in der Datenbank
        layout: {
            id: "yxz",
            base: {
                background: "#F1F1F1",
            },
            headline1: {
                visible: true,
                size: 330,
                lineHeight: 100,
                text: "HELLO",
                align: "CENTER",
                pos: {
                    x: 820,
                    y: 400,
                },
                rotate: {
                    x: 0,
                    y: 0,
                    z: 0
                },
                color: "#7FFFD4",
                font: "fonts/p5Fonts/AlfaSlabOne-Regular.ttf",
                update: false
            },
            headline2: {
                visible: false,
                size: 120,
                lineHeight: 100,
                text: "BONJOUR",
                align: "CENTER",
                pos: {
                    x: 820,
                    y: 700
                },
                rotate: {
                    x: 0,
                    y: 0,
                    z: 0
                },
                color: "#FFCF00",
                font: "fonts/p5Fonts/AlfaSlabOne-Regular.ttf",
                update: false
            },
            text: {
                visible: true,
                size: 30,
                lineHeight: 1,
                width: 600,
                text:
                    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
                align: "LEFT",
                pos: {
                    x: 0,
                    y: 0,
                },
                color: "#FF0460",
            },
            image1: {
                visible: true,
                url: "images/bust.jpg",
                update: false,
                scale: 100,
                pos: {
                    x: 820,
                    y: 594,
                },
                rotate: {
                    x: 0,
                    y: 0,
                    z: 0,
                },
            },
            image2: {
                visible: false,
                url: "images/moon.jpg",
                update: false,
                scale: 60,
                pos: {
                    x: 400,
                    y: 400,
                },
                rotate: {
                    x: 0,
                    y: 0,
                    z: 0,
                },
            },
            currentlyEditing: false,
            isCover: true,
            title: '',
            author: ''
        },
        // ab hier ist alles UI / globale Settings
        ui: {
            activeUi: "BASIS",
            showToolbox: true,
        },
        colors: [
            // Buchstaben müssen Uppercase sein!
            "#FF0460",
            "#B000FF",
            "#FF1B00",
            "#FFCF00",
            "#A48545",
            "#31FF17",
            "#7FFFD4",
            "#0000FF",
            "#000000",
            "#F1F1F1"
        ],
        imageCollections: [],
        fontCollection: fonts,
        spreads: [],
        modalImages: {
            visible: false,
            target: 1,
        },
        modalFonts: {
            visible: false,
            target: 1,
        },
        modalSpreads: {
            visible: true,
        },
        modalInfo: {
            visible: false
        },
        saveImage: false,
        isAdmin: true
    },
    mutations: {
        //////////////////////
        // Images
        //////////////////////
        changeImages(state, newCollection) {
            state.imageCollections = newCollection;
        },
        
        //////////////////////
        // Spreads
        //////////////////////
        changeSpreads(state, newSpreads) {
            state.spreads = newSpreads;
        },
        reloadSpreads(state) {
            Vue.axios.get(state.host + 'zine/api/' + state.id).then((response) => {
                state.spreads = response.data.spreads;
                state.magtitle = response.data.magtitle;
                state.magauthor = response.data.magauthor;
            })
        },

        //////////////////////
        // ADMIN
        //////////////////////

        toggleAdmin(state) {
            state.isAdmin = !state.isAdmin;
        },

        //////////////////////
        // Ui: Toolbox
        //////////////////////

        toggleToolbox(state) {
            state.ui.showToolbox = !state.ui.showToolbox;
        },

        //////////////////////
        // Base
        //////////////////////

        changeBackground(state, color) {
            state.layout.base.background = color;
        },


        //////////////////////
        // Headline 1
        //////////////////////

        changeHeadline1Size(state, val) {
            state.layout.headline1.size = parseInt(val);
        },
        changeHeadline1LineHeight(state, val) {
            state.layout.headline1.lineHeight = parseInt(val);
        },
        changeHeadline1(state, val) {
            state.layout.headline1.text = val;
        },
        repositionHeadline1(state, newpos) {
            state.layout.headline1.pos.x = newpos.x;
            state.layout.headline1.pos.y = newpos.y;
        },    
        changeHeadline1Color(state, newcolor) {
            state.layout.headline1.color = newcolor;
            state.layout.headline1.color = newcolor;
        },  
        toggleHeadline1Visibility(state) {
            state.layout.headline1.visible = !state.layout.headline1.visible;
        },
        changeHeadline1Alignment(state, val) {
            state.layout.headline1.align = val;
        },
        updateHeadline1Font(state) {
            state.layout.headline1.update = false;
        },
        rotateHeadline1X(state, newRotation) {
            state.layout.headline1.rotate.x = newRotation;
        },
        rotateHeadline1Y(state, newRotation) {
            state.layout.headline1.rotate.y = newRotation;
        },
        rotateHeadline1Z(state, newRotation) {
            state.layout.headline1.rotate.z = newRotation;
        },        

        //////////////////////
        // Headline 2
        //////////////////////

        changeHeadline2Size(state, val) {
            state.layout.headline2.size = parseInt(val);
        },
        changeHeadline2LineHeight(state, val) {
            state.layout.headline2.lineHeight = parseInt(val);
        },
        changeHeadline2(state, val) {
            state.layout.headline2.text = val;
        },
        repositionHeadline2(state, newpos) {
            state.layout.headline2.pos.x = newpos.x;
            state.layout.headline2.pos.y = newpos.y;
        },    
        changeHeadline2Color(state, newcolor) {
            state.layout.headline2.color = newcolor;
            state.layout.headline2.color = newcolor;
        },  
        toggleHeadline2Visibility(state) {
            state.layout.headline2.visible = !state.layout.headline2.visible;
        },
        changeHeadline2Alignment(state, val) {
            state.layout.headline2.align = val;
        },
        updateHeadline2Font(state) {
            state.layout.headline2.update = false;
        },
        rotateHeadline2X(state, newRotation) {
            state.layout.headline2.rotate.x = newRotation;
        },
        rotateHeadline2Y(state, newRotation) {
            state.layout.headline2.rotate.y = newRotation;
        },
        rotateHeadline2Z(state, newRotation) {
            state.layout.headline2.rotate.z = newRotation;
        },       

        //////////////////////
        // Fließtext
        //////////////////////

        toggleTextVisibility(state) {
            state.layout.text.visible = !state.layout.text.visible;
        },        

        changeText(state, val) {
            state.layout.text.text = val;
        },
        repositionText(state, newpos) {
            state.layout.text.pos.x = newpos.x;
            state.layout.text.pos.y = newpos.y;
        },   
        updateTextColor(state, newcolor) {
            state.layout.text.color = newcolor;
        },  
        updateTextSize(state, val) {
            state.layout.text.size = val;
        },
        updateTextLineHeight(state, val) {
            state.layout.text.lineHeight = val;
        },
        updateTextWidth(state, val) {
            state.layout.text.width = val;
        },           
        changeTextAlignment(state, val) {
            state.layout.text.align = val;
        },     
        
        //////////////////////
        // Modal: Info
        //////////////////////

        toggleModalInfo(state) {
            state.modalInfo.visible = !state.modalInfo.visible;
        },

        //////////////////////
        // Modal: Images
        //////////////////////

        toggleModalImages(state) {
            state.modalImages.visible = !state.modalImages.visible;
        },
        toggleModalSpreads(state) {
            state.modalSpreads.visible = !state.modalSpreads.visible;
        },
        setImageTarget(state, newtarget) {
            //console.log(newtarget);
            state.modalImages.target = newtarget;
        },        

        //////////////////////
        // Modal: Fonts
        //////////////////////

        toggleModalFonts(state) {
            state.modalFonts.visible = !state.modalFonts.visible;
        },
        updateFont1(state, fontUrl) {
            state.layout.headline1.font = fontUrl;
            state.layout.headline1.update = true;
        },
        updateFont2(state, fontUrl) {
            state.layout.headline2.font = fontUrl;
            state.layout.headline2.update = true;
        },
/*         toggleModalSpreads(state) {
            state.modalSpreads.visible = !state.modalSpreads.visible;
        }, */
        setFontsTarget(state, newtarget) {
            state.modalFonts.target = newtarget;
        },        

        //////////////////////
        // Switch UI
        //////////////////////

        switchUi(state, e) {
            state.ui.activeUi = e;
        },

        //////////////////////
        // Image 1
        //////////////////////

        toggleImage1Visibility(state) {
            state.layout.image1.visible = !state.layout.image1.visible;
        },
        scaleImage1(state, newscale) {
            state.layout.image1.scale = newscale;
        },
        rotateImage1X(state, newRotation) {
            state.layout.image1.rotate.x = newRotation;
        },
        rotateImage1Y(state, newRotation) {
            state.layout.image1.rotate.y = newRotation;
        },
        rotateImage1Z(state, newRotation) {
            state.layout.image1.rotate.z = newRotation;
        },
        updateImage1(state, path) {
            state.layout.image1.url = path;
            state.layout.image1.update = true;
        },
        updateImage1False(state) {
            state.layout.image1.update = false;
        },
        updateImage1True(state) {
            state.layout.image1.update = true;
        },
        repositionImage1(state, newpos) {
            state.layout.image1.pos.x = newpos.x;
            state.layout.image1.pos.y = newpos.y;
        },
        saveImageFalse(state) {
            state.saveImage = false;
        },
        saveImageTrue(state) {
            state.saveImage = true;
        },
        
        //////////////////////
        // Image 2
        //////////////////////

        updateImage2(state, path) {
            state.layout.image2.url = path;
            state.layout.image2.update = true;
        },
        updateImage2False(state) {
            state.layout.image2.update = false;
        },
        updateImage2True(state) {
            state.layout.image2.update = true;
        },
        repositionImage2(state, newpos) {
            state.layout.image2.pos.x = newpos.x;
            state.layout.image2.pos.y = newpos.y;
        },
        scaleImage2(state, newscale) {
            state.layout.image2.scale = newscale;
        },
        rotateImage2X(state, newRotation) {
            state.layout.image2.rotate.x = newRotation;
        },
        rotateImage2Y(state, newRotation) {
            state.layout.image2.rotate.y = newRotation;
        },
        rotateImage2Z(state, newRotation) {
            state.layout.image2.rotate.z = newRotation;
        },
        toggleImage2Visibility(state) {
            state.layout.image2.visible = !state.layout.image2.visible;
        },

        //////////////////////
        // SAVE LAYOUT
        //////////////////////
        
        saveLayout: (state) => {
            state.modalSpreads.visible = true;

            const canvas = document.getElementById('defaultCanvas0');
            const base64 = canvas.toDataURL();

            // https://www.codegrepper.com/code-examples/javascript/axios+file+upload
            const formData = new FormData();
            // const imagefile = document.querySelector('#file');
            formData.append("zineslug", state.id);
            formData.append("layoutslug", state.layout.id);
            formData.append("base64", base64);
            formData.append("layout", JSON.stringify(state.layout));
            
            Vue.axios.post(state.host + '/de/open-space/zine/save-layout', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                Vue.axios.get(state.host + 'zine/api/' + state.id).then((response) => {
                    state.spreads = response.data.spreads;
                    state.magtitle = response.data.magtitle;
                    state.magauthor = response.data.magauthor;
                })
            })
        },
        
        //////////////////////
        // LOAD LAYOUT
        //////////////////////
        
        loadLayout: (state, layoutId) => {
            console.log("load Layout: " + state.host + 'zine/api/' + state.id + '/' + layoutId);
            

            // state.modalSpreads.visible = !state.modalSpreads.visible;
            state.modalSpreads.visible = false;

            Vue.axios.get(state.host + 'zine/api/' + state.id + '/' + layoutId).then((response) => {
                const newSpreadData = response.data;
                
                // Objekte kann man so nicht überschreiben.
                // Alle Props einzeln überschreiben?
                
                // check if first or last page
                state.layout.isCover = newSpreadData.layout.isCover;
                
                // id
                state.layout.id = newSpreadData.layout.id
    
                // Background
                state.layout.base.background = newSpreadData.layout.base.background;
    
                // Headline
                state.layout.headline1.visible =
                    newSpreadData.layout.headline1.visible;
                state.layout.headline1.size = newSpreadData.layout.headline1.size;
                state.layout.headline1.lineHeight =
                    newSpreadData.layout.headline1.lineHeight;
                state.layout.headline1.text = newSpreadData.layout.headline1.text;
                state.layout.headline1.align = newSpreadData.layout.headline1.align;
                state.layout.headline1.pos.x = newSpreadData.layout.headline1.pos.x;
                state.layout.headline1.pos.y = newSpreadData.layout.headline1.pos.y;
                state.layout.headline1.color = newSpreadData.layout.headline1.color;
                state.layout.headline1.align = newSpreadData.layout.headline1.align;
    
                //Headline 2
                state.layout.headline2.visible =
                newSpreadData.layout.headline2.visible;
                state.layout.headline2.size = newSpreadData.layout.headline2.size;
                state.layout.headline2.lineHeight =
                newSpreadData.layout.headline2.lineHeight;
                state.layout.headline2.text = newSpreadData.layout.headline2.text;
                state.layout.headline2.align = newSpreadData.layout.headline2.align;
                state.layout.headline2.pos.x = newSpreadData.layout.headline2.pos.x;
                state.layout.headline2.pos.y = newSpreadData.layout.headline2.pos.y;
                state.layout.headline2.color = newSpreadData.layout.headline2.color;
    
                // Text
                state.layout.text.visible = newSpreadData.layout.text.visible;
                state.layout.text.size = newSpreadData.layout.text.size;
                state.layout.text.lineHeight = newSpreadData.layout.text.lineHeight;
                state.layout.text.text = newSpreadData.layout.text.text;
                state.layout.text.align = newSpreadData.layout.text.align;
                state.layout.text.pos.x = newSpreadData.layout.text.pos.x;
                state.layout.text.pos.y = newSpreadData.layout.text.pos.y;
                state.layout.text.color = newSpreadData.layout.text.color;
                state.layout.text.width = newSpreadData.layout.text.width;
    
                // Image1
                state.layout.image1.visible = newSpreadData.layout.image1.visible;
                state.layout.image1.url = newSpreadData.layout.image1.url;
                state.layout.image1.update = true;
                state.layout.image1.scale = newSpreadData.layout.image1.scale;
                state.layout.image1.pos.x = newSpreadData.layout.image1.pos.x;
                state.layout.image1.pos.y = newSpreadData.layout.image1.pos.y;
    
                // Image2
                state.layout.image2.visible = newSpreadData.layout.image2.visible;
                state.layout.image2.url = newSpreadData.layout.image2.url;
                state.layout.image2.update = true;
                state.layout.image2.scale = newSpreadData.layout.image2.scale;
                state.layout.image2.pos.x = newSpreadData.layout.image2.pos.x;
                state.layout.image2.pos.y = newSpreadData.layout.image2.pos.y;
            })


        },
        // END: LOAD LAYOUT


        //////////////////////
        // move Spreads in Spreadview
        //////////////////////


        moveSpreadLeft(state, index) {
            /* check if it's the first page -> don't move this page (index == 1)*/
            if (index !== 1) {
                let spread = state.spreads[index];
                state.spreads.splice(index, 1);
                let newIndex = index - 1;
                state.spreads.splice(newIndex, 0, spread);
            }
        },
        moveSpreadRight(state, index) {
            /* check if it's the second to last page -> don't move this page (index == state.spreads.length - 2) */
            if (index !== state.spreads.length - 2) {
                let spread = state.spreads[index];
                state.spreads.splice(index, 1);
                let newIndex = index + 1;
                state.spreads.splice(newIndex, 0, spread);
            }
        },
        
      
    },
});

new Vue({
    render: (h) => h(App),
    store,
}).$mount("#app");
