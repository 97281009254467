<template>
  <div class="alignButtons">
    <button @click="changeAlignment('LEFT')">
      <img
        src="@/assets/align_left.png"
        alt="linksbündig"
        :class="{ selected: selectedAlignment(typoSelection) == 'LEFT' }"
      />
    </button>
    <button @click="changeAlignment('CENTER')">
      <img
        src="@/assets/align_center.png"
        alt="zentriert"
        :class="{ selected: selectedAlignment(typoSelection) == 'CENTER' }"
      />
    </button>
    <button @click="changeAlignment('RIGHT')">
      <img
        src="@/assets/align_right.png"
        alt="rechtsbündig"
        :class="{ selected: selectedAlignment(typoSelection) == 'RIGHT' }"
      />
    </button>
  </div>
</template>

<script>
export default {
  props: { typoSelection: String },

  mounted() {
    console.log(this.selectedAlignment(this.typoSelection));
  },
  methods: {
    changeAlignment(alignment) {
      /* props werden vom Parent übergeben (entweder "headline1" oder "headline2" oder "text". Damit wird jeweils die passende Mutation im State vorgenommen und an den Canvas übergeben) */
      if (this.typoSelection == "headline1") {
        this.$store.commit("changeHeadline1Alignment", alignment);
      }
      if (this.typoSelection == "headline2") {
        this.$store.commit("changeHeadline2Alignment", alignment);
      }
      if (this.typoSelection == "text") {
        this.$store.commit("changeTextAlignment", alignment);
      }
    },
    selectedAlignment(selected) {
      if (selected == "headline1") {
        return this.$store.state.layout.headline1.align;
      }
      if (selected == "headline2") {
        return this.$store.state.layout.headline2.align;
      } else {
        return this.$store.state.layout.text.align;
      }
    },
  },
};
</script>

<style scoped>
button {
  width: 32px;
  background: none;
  outline: none;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}

button img {
  opacity: 0.5;
}

.selected {
  opacity: 1;
}

button img:hover {
  opacity: 1;
}

button:first-of-type {
  margin-left: 0;
}
</style>