<template>
  <div
    id="modalFonts"
    class="modal"
    v-bind:class="{ active: modalFontsVisible }"
  >
    <div id="close" v-on:click="toggleModalFonts">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        background="var(--green-700__digizine)"
        viewBox="0 0 24 24"
        stroke="var(--green-700__digizine)"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </div>

    <h2 class="modal-heading">Schrift auswählen</h2>
    <div
      v-for="(font, index) in fontCollection"
      :key="index"
      class="selectFont"
    >
      <!--       <h3>{{ font.name }}</h3>
 -->
      <img
        class="font-preview"
        :src="font.preview"
        :alt="font.name"
        @click="updateFont(font.url)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalFonts",
  data() {
    return {
      whichFontShouldUpdate: "",
    };
  },
  computed: {
    fontCollection() {
      return this.$store.state.fontCollection;
    },
    modalFontsVisible() {
      return this.$store.state.modalFonts.visible;
    },
    fontsTarget() {
      return this.$store.state.modalFonts.target;
    },
  },
  methods: {
    toggleModalFonts() {
      this.$store.commit("toggleModalFonts");
    },
    updateFont(fontUrl) {
      if (this.fontsTarget == 1) {
        this.$store.commit("updateFont1", fontUrl);
      } else if (this.fontsTarget == 2) {
        this.$store.commit("updateFont2", fontUrl);
      }
      this.toggleModalFonts();
    },
  },
};
</script>

<style>
#modalFonts {
  padding: 45px 45px 100px 84px;
}

#modalFonts h2 {
  font-weight: 500 !important;
  font-size: 25px;
  margin-bottom: 85px;
}

#close svg {
  width: 40px;
  height: 40px;
}

#close svg:hover {
  stroke: var(--green-500__digizine);
}

.modal-heading {
  color: var(--green-700__digizine);
  margin-top: 4px;
  margin-bottom: 20px;
}

.font-preview {
  display: block !important;
  height: 80px !important;
  width: auto !important;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.font-preview:hover {
  transform: translateX(10px);
  filter: saturate(2);
}

.selectFont h3 {
  margin-bottom: 10px;
}
</style>