<template>
  <div class="spreadParent" ref="spread">
    <div
      class="spreadWrapper"
      @click="loadLayout(spreadData.layout.currentlyEditing)"
    >
      <div
        class="spread"
        v-bind:style="{
          backgroundImage: 'url(' + spreadData.thumbnail + ')',
        }"
      ></div>
      <div class="spreadOverlay"></div>
      <div
        v-if="spreadData.layout.currentlyEditing"
        class="spreadOverlay--workingOnIt"
      >
        wird gerade bearbeitet
      </div>
    </div>
    <div class="spreadOrder-controls" v-show="isAdmin">
      <!-- show left arrow NOT on first spread and also NOT on the last spread -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        class="spreadOrder-arrow"
        viewBox="0 0 24 24"
        @click="moveLeft(index)"
        v-show="(index !== 0 && index !== 1) || lastSpread == true"
        v-if="!lastSpread"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 19l-7-7 7-7"
        />
      </svg>

      <!-- show right arrow NOT on second to last spread and also NOT on the last spread -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        class="spreadOrder-arrow"
        viewBox="0 0 24 24"
        @click="moveRight(index)"
        v-show="index !== $store.state.spreads.length - 1 && index !== 0"
        v-if="!lastSpread"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 5l7 7-7 7"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spread",
  props: ["spreadData", "index", "lastSpread", "id"],
  layouts() {
    return this.$store.state.spreads;
  },
  computed: {
    isAdmin() {
      return this.$store.state.isAdmin;
    },
  },
  methods: {
    loadLayout(isEditing) {
      if (!isEditing) {
        this.$store.commit("loadLayout", this.id);
      }
    },
    moveLeft(index) {
      this.$store.commit("moveSpreadLeft", index);
    },
    moveRight(index) {
      this.$store.commit("moveSpreadRight", index);
    },
  },
};
</script>

<style>
.spreadParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 0.25s ease;
}

.spreadParent:first-child .spreadOrder-controls {
  justify-content: flex-start;
}
.spreadParent:last-child .spreadOrder-controls {
  justify-content: flex-end;
}

.spread {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.spreadUi {
  text-align: center;
  margin-top: 10px;
}

.spreadWrapper {
  position: relative;
  width: 100%;
  padding-bottom: 75%;
  background: #f1f1f1;
  -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  margin: 0;
  cursor: pointer;
}

.spreadWrapper:hover {
  box-shadow: 0 0 10px var(--green-700__digizine);
}

.spreadOverlay {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 0.1) 100%
  );
}

.spreadOverlay--workingOnIt {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #bcbcbce2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: wait;
  padding: 2px;
  text-align: center;
}

.spreadOrder-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.spreadOrder-arrow {
  height: 24px;
  stroke: var(--green-700__digizine);
  cursor: pointer;
  margin-left: 5px;
}

.spreadOrder-arrow:hover {
  stroke: var(--green-500__digizine);
}

.spreadParent:first-child .spreadWrapper {
  width: 50%;
  left: 50%;
}

.spreadParent:first-child .spread {
  background-size: 200% !important;
  background-position: right;
}

.spreadParent:last-child .spread {
  background-size: 200% !important;
  background-position: left;
}

.spreadParent:first-child .spreadOverlay {
  width: 100%;
}

.spreadParent:last-child .spreadWrapper {
  width: 50%;
  left: 0;
}

.spreadParent:last-child .spreadOverlay {
  width: 100%;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 0.1) 100%
  );
}
</style>
