<template>
  <div id="modalInfo" class="modal" v-bind:class="{ active: modalInfoVisable }">
    <h3>
      Mit der Veröffentlichung können keine Inhalte mehr am Digizine geändert
      werden. Möchtest Du das Digizine jetzt veröffentlichen?
    </h3>
    <div class="modalInfo-controls">
      <button @click="goBack" class="modalInfo-btn">Zurück</button>
      <button @click="confirm" class="modalInfo-btn">Alles klar</button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    modalInfoVisable() {
      return this.$store.state.modalInfo.visible;
    },
  },
  methods: {
    goBack() {
      /* go back */
      this.$store.commit("toggleModalInfo");
    },
    confirm() {
      /* go further */
      this.$store.commit("toggleModalInfo");
    },
  },
};
</script>

<style>
#modalInfo {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.865);
  z-index: 10000;
}

.modalInfo-btn {
  cursor: pointer;
  padding: 14px 33px 10px 33px;
  border-radius: 30px;
  background: var(--green-700__digizine);
  color: white !important;
  text-transform: uppercase;
  border: 1px solid var(--green-700__digizine);
  transition: all 0.25s ease-in-out;
  margin-right: 20px;
  letter-spacing: 1.9px;
}

#modalInfo h3 {
  max-width: 600px;
  text-align: center;
  color: var(--green-700__digizine);
  font-size: 25px;
  font-weight: 500;
}

.modalInfo-btn:hover {
  background: var(--green-100__digizine) !important;
  color: var(--green-700__digizine) !important;
}
</style>