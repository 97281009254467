<template>
  <div id="saveBtnWrapper" >
     <div 
      id="cancelBtn" class="clbBtn"
      @click="cancel()"> Abbrechen </div>
    <div 
      id="saveBtn" class="clbBtn  clbBtn--primary"
      @click="save()"> Speichern </div>
  </div>
</template>

<script>
export default {
  name: "SaveBtn",
  methods: {
    save() {
      this.$store.commit("saveLayout");
    },
    cancel() {
      this.$store.commit("toggleModalSpreads");
    }
  }
};
</script>

<style>
#saveBtnWrapper {
  position: absolute;
  bottom: 60px;
  right: 77px;
}

.clbBtn {
  display: inline-block;
  margin-right: 30px;
  cursor: pointer;
  padding: 14px 33px 10px 33px;
  border-radius: 30px;
  
  color: var(--green-700__digizine);
  text-transform: uppercase;
  border: 1px solid var(--green-700__digizine);
  transition: all 0.25s ease-in-out;
  letter-spacing: 1.9px;
}

.clbBtn--primary {
  background: var(--green-700__digizine);
  color: white !important;
}
.clbBtn:hover {
  background: var(--green-100__digizine) !important;
  color: var(--green-700__digizine) !important;
}
</style>