<template>
  <div id="spreadview" v-bind:class="{ active: modalSpreadsVisible }">
    <!--     <div class="btnClose" v-on:click="toggleModalSpreads">CLOSE</div>
 -->

    <p class="introText" v-if="isAdmin">
      Wähle eine Doppelseite aus, um mit dem Gestalten zu beginnen. Als Admin
      gibst Du den Titel und die Autor*innen des Digizines ein. Du kannst die
      Seitenabfolge mit den Pfeilen verändern und das fertige Digizine
      herunterladen und veröffentlichen.
    </p>
    <p v-else class="introText">
      Wähle eine Doppelseite aus, um mit dem Gestalten zu beginnen.
    </p>

    <div class="refresh">
      <button @click="reloadSpreads">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          />
        </svg>
        Aktualisieren
      </button>
    </div>

    <div class="credits" v-if="isAdmin">
      <div class="credits__field credits__title">
        <h3>Titel des Digizines:</h3>
        <input
          type="text"
          placeholder="max. 35 Zeichen"
          minlength="1"
          maxlength="35"
          v-model="$store.state.magtitle"
        />
      </div>
      <div class="credits__field credits__author">
        <h3>Autor*in / Gruppenname:</h3>
        <input
          type="text"
          placeholder="max. 35 Zeichen"
          minlength="1"
          maxlength="35"
          v-model="$store.state.magauthor"
        />
      </div>
    </div>

    <div id="spreads">
      <Spread
        v-for="(layout, index) in $store.state.spreads"
        :key="layout.id" 
        :id="layout.id"
        :spreadData="layout"
        :index="index"
        :lastSpread="false"
      />

      <!-- last spread is first spread -->
      <Spread :spreadData="titleLayout" :lastSpread="true" />
    </div>

    <div class="publishBtns" v-if="isAdmin">
      <button>ALS PDF EXPORTIEREN</button>
      <button>PUBLIZIEREN</button>
    </div>
  </div>
</template>

<script>
import Spread from "./Spread";
export default {
  name: "SpreadView",
  components: {
    Spread,
  },
  // data() {
  //   return {
  //     spreadViews: {},
  //   };
  // },
  computed: {
    modalSpreadsVisible() {
      return this.$store.state.modalSpreads.visible;
    },
    titleLayout() {
      return this.$store.state.spreads[0];
    },
    magtitle() {
      return this.$store.state.magtitle;
    },
    magauthor() {
      return this.$store.state.magauthor;
    },
    isAdmin() {
      return this.$store.state.isAdmin;
    },
    spreadViews() {
      return this.$store.state.spreads
    }
  },
  methods: {
    toggleModalSpreads() {
      this.$store.commit("toggleModalSpreads");
    },
    spreads() {
      // load initial spreads from database
      // also refresh spreads on refresh-click with button
      this.spreadViews = this.$store.state.spreads;
    },
    reloadSpreads() {
      this.$store.commit("reloadSpreads");
      // this.axios.get(this.$store.state.host + 'api/' + this.$store.state.id).then((response) => {
      //   this.$store.commit("changeSpreads", response.data);
      //   // this.spreads();
      // })
    },
  },
  mounted() {
    this.reloadSpreads();
  },
};
</script>

<style scoped>
.ui-outer-wrapper--spreadview {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
}

#spreadview {
  padding: 45px 45px 100px 84px;
  z-index: 9999;
  position: fixed;
  top: 100vh;
  left: 0;
  transition: top 0.4s ease;
  width: 100%;
  height: 100vh;
  background: rgb(250, 250, 250);
  overflow-y: auto;
}

#spreadview .introText {
  margin-top: 0;
  color: var(--brown-700__digizine);
  max-width: 800px;
}

#spreadview .refresh button {
  display: flex;
  align-items: center;
  height: 40px;
  margin: 45px 0 25px 0;
  letter-spacing: 1.9px;
  text-transform: uppercase;
}

#spreadview .refresh svg {
  height: 200%;
  margin-right: 10px;
}

#spreadview.active {
  top: 0;
}

/* #spreadview * {
  color: var(--green);
} */

#spreads {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
  margin-top: 60px;
}

.btnClose {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 15px;
}

p {
  font-size: 19px;
  font-weight: 600;
  color: var(--green);
}

.credits {
  display: flex;
}

.credits__field {
  display: flex;
  margin-top: 20px;
  margin-right: 20px;
}

.credits__field h3 {
  display: block;
  background: white;
  color: var(--green-700__digizine);
  padding: 7px 7px 7px 14px;
  margin: 0;
  border-top: 1px solid var(--gray-700__digizine);
  border-left: 1px solid var(--gray-700__digizine);
  border-bottom: 1px solid var(--gray-700__digizine);
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  height: 45px;
  line-height: 1.9;
}

.credits__field input {
  border: 1px solid var(--gray-700__digizine);
  transform: translateX(-2px);
  border-left: 1px solid transparent;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  min-width: 250px;
  outline: none;
  color: var(--green-700__digizine);
  font-size: 1.17em;
  padding: 7px 20px 7px 7px;
  line-height: 1;
  height: 45px;
}

#spreadview .publishBtns button {
  margin-left: 0;
  margin-right: 25px;
}

button {
  margin-top: 100px;
  margin-left: 50px;
  padding: 15px 30px;
  height: 45px;
  background: var(--green-700__digizine);
  color: white;
  border: 1px solid var(--green-700__digizine);
  border-radius: 20px;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  letter-spacing: 1.9px;
}

button:last-of-type {
  margin-left: 30px;
}

button:hover {
  color: var(--green-700__digizine);
  background: var(--green-100__digizine);
}

#back-btn svg:hover {
  background: white !important;
  stroke: var(--green-700__digizine) !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active {
  transition-delay: 1s;
}
</style>
