<template>
  <div id="uiHeadline">
    <div class="input-textarea">
      <textArea
        rows="3"
        :placeholder="headline1"
        v-model="headline1"
        v-on:input="updateHeadline1"
      />
    </div>
    <div class="input">
      <h4>Größe</h4>
      <input
        type="range"
        min="50"
        max="600"
        v-bind:value="headline1Size"
        v-on:input="updateHeadline1Size"
      />
    </div>
    <div class="input">
      <h4>Linienhöhe</h4>
      <input
        type="range"
        min="0"
        max="200"
        step="1"
        v-bind:value="headline1LineHeight"
        v-on:input="updateHeadline1LineHeight"
      />
    </div>
    <div class="input">
      <h4>Rotation X</h4>
      <input
        type="range"
        min="0"
        max="360"
        v-bind:value="headline1RotationX"
        v-on:input="rotateX"
      />
    </div>
    <div class="input">
      <h4>Rotation Y</h4>
      <input
        type="range"
        min="0"
        max="360"
        v-bind:value="headline1RotationY"
        v-on:input="rotateY"
      />
    </div>
    <div class="input">
      <h4>Rotation Z</h4>
      <input
        type="range"
        min="0"
        max="360"
        v-bind:value="headline1RotationZ"
        v-on:input="rotateZ"
      />
    </div>
    <div class="input">
      <h4>Farbe</h4>
      <div class="colors">
        <div
          class="color"
          @click="updateHeadline1Color(color)"
          v-for="(color, index) in colors"
          v-bind:key="index"
          v-bind:style="{ backgroundColor: color }"
          ref="headline1Color"
        ></div>
      </div>
    </div>

    <div class="input">
      <h4>Ausrichtung</h4>
      <TextAlign typoSelection="headline1" />
    </div>

    <div class="input input-lessRound">
      <h4>Schrift wählen</h4>
      <div class="imageSelectWrapper" v-on:click="toggleModalFonts()">
        <!-- <img v-bind:src="currentImage" /> -->
        <!-- <div class="imageSelectOverlay">
                    Select
                </div> -->
        Auswählen
      </div>
    </div>
  </div>
</template>

<script>
import TextAlign from "@/components/ui/TextAlign.vue";

export default {
  name: "UiHeadline1",
  components: {
    TextAlign,
  },
  mounted() {
    this.updateHeadline1UI();
  },
  computed: {
    headline1Size() {
      return this.$store.state.layout.headline1.size;
    },
    headline1() {
      return this.$store.state.layout.headline1.text;
    },
    headline1LineHeight() {
      return this.$store.state.layout.headline1.lineHeight;
    },
    colors() {
      return this.$store.state.colors;
    },
    headline1RotationX() {
      return this.$store.state.layout.image1.rotate.x;
    },
    headline1RotationY() {
      return this.$store.state.layout.image1.rotate.y;
    },
    headline1RotationZ() {
      return this.$store.state.layout.image1.rotate.z;
    },
  },
  methods: {
    rotateX(e) {
      this.$store.commit("rotateHeadline1X", e.target.value);
    },
    rotateY(e) {
      this.$store.commit("rotateHeadline1Y", e.target.value);
    },
    rotateZ(e) {
      this.$store.commit("rotateHeadline1Z", e.target.value);
    },
    updateHeadline1Size(e) {
      this.$store.commit("changeHeadline1Size", e.target.value);
    },
    updateHeadline1LineHeight(e) {
      this.$store.commit("changeHeadline1LineHeight", e.target.value);
    },
    updateHeadline1(e) {
      this.$store.commit("changeHeadline1", e.target.value);
    },
    updateHeadline1Color(color) {
      this.$store.commit("changeHeadline1Color", color);
      this.updateHeadline1UI();
    },
    toggleModalFonts() {
      this.$store.commit("toggleModalFonts");
      this.$store.commit("setFontsTarget", 1);
    },
    updateHeadline1UI() {
      //holt alle Farben vom UI ab und cleared die selected Farbe
      let colors = this.$refs.headline1Color;
      colors.forEach((color) => {
        color.classList.remove("selectedColor");
      });

      // vergleicht, was die selektierte Farbe ist und fügt "selectedColor" als CSS Klasse hinzu
      let selectedColor = colors.filter(
        (color) =>
          this.rgb2hex(color.style.backgroundColor) ==
          this.$store.state.layout.headline1.color
      );
      selectedColor[0].classList.add("selectedColor");
    },
    rgb2hex(rgb) {
      // konvertiert Farben von RGB in HEX.
      return `#${rgb
        .match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
        .slice(1)
        .map((n) => parseInt(n, 10).toString(16).toUpperCase().padStart(2, "0"))
        .join("")}`;
    },
  },
};
</script>

<style>
.selectedColor:after {
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  background: transparent;
  border: 2px solid white;
  border-radius: 100%;
  content: "";
}
</style>