<template>
  <div>
    <div class="input">
      <h4>Hintergrund</h4>
      <div class="colors">
        <div
          class="color"
          @click="changeBackground(color)"
          v-for="(color, index) in colors"
          v-bind:key="index"
          v-bind:style="{ backgroundColor: color }"
          ref="basisColor"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UiBasis",
  mounted() {
    this.updateBasisColorUI();
  },
  computed: {
    colors() {
      return this.$store.state.colors;
    },
  },
  methods: {
    changeBackground(color) {
      this.$store.commit("changeBackground", color);
      this.updateBasisColorUI();
    },
    updateBasisColorUI() {
      //holt alle Farben vom UI ab und cleared die selected Farbe
      let colors = this.$refs.basisColor;
      colors.forEach((color) => {
        color.classList.remove("selectedColor");
      });

      // vergleicht, was die selektierte Farbe ist und fügt "selectedHeadLine1" als CSS Klasse hinzu
      let selectedColor = colors.filter(
        (color) =>
          this.rgb2hex(color.style.backgroundColor) ==
          this.$store.state.layout.base.background
      );
      selectedColor[0].classList.add("selectedColor");
    },
    rgb2hex(rgb) {
      // konvertiert Farben von RGB in HEX.
      return `#${rgb
        .match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
        .slice(1)
        .map((n) => parseInt(n, 10).toString(16).toUpperCase().padStart(2, "0"))
        .join("")}`;
    },
  },
};
</script>
