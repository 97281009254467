<template>
  <div id="app" class="noselect">
    <Ui></Ui>
    <Artboard></Artboard>
    <ModalImages />
    <ModalFonts />
    <SpreadView />
    <SaveBtn key="save" />
    <ModalInfo />
  </div>
</template>

<script>
import "./css/style.css";
import "./css/rangeSlider.css";
import "./css/buttons.css";

import Ui from "./components/Ui.vue";
import Artboard from "./components/Artboard.vue";
import ModalImages from "./components/ModalImages.vue";
import ModalFonts from "./components/ModalFonts.vue";
import SpreadView from "./components/spreadview/SpreadView.vue";
import ModalInfo from "./components/ModalInfo.vue";
import SaveBtn from "./components/ui/SaveBtn.vue";

export default {
  name: "App",
  components: {
    Ui,
    Artboard,
    ModalImages,
    ModalFonts,
    SpreadView,
    ModalInfo,
    SaveBtn,
  },
  methods: {
  },
};
</script>

<style>
:root {
  --yellow__digizine: #f7ef00;

  --green-700__digizine: #0ae601;
  --green-500__digizine: #84ff80;
  --green-100__digizine: #e6ffe5;

  --brown-700__digizine: #a48545;
  --brown-500__digizine: #ccb485;
  --brown-100__digizine: #f1ebdf;

  --gray-700__digizine: #747474;
  --gray-500__digizine: #bcbcbc;
  --gray-100__digizine: #d9d9d9;

  --red__digizine: #ff085c;
}
</style>
