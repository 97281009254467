<template>
  <div id="uiImage1">
    <div class="input noBorder">
      <div
        class="imageSelectWrapper imageSelectWrapper--Image"
        v-on:click="toggleModalImages"
      >
        <!-- <img v-bind:src="currentImage" /> -->
        <!-- <div class="imageSelectOverlay">
                    Select
                </div> -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 4v16m8-8H4"
          />
        </svg>
        Foto auswählen
      </div>
    </div>
    <div class="input">
      <h4>Größe</h4>
      <input
        type="range"
        min="0"
        max="500"
        v-bind:value="imageScale"
        v-on:input="scaleImage"
      />
    </div>
    <div class="input">
      <h4>Rotation X</h4>
      <input
        type="range"
        min="0"
        max="360"
        v-bind:value="image2RotationX"
        v-on:input="rotateX"
      />
    </div>
    <div class="input">
      <h4>Rotation Y</h4>
      <input
        type="range"
        min="0"
        max="360"
        v-bind:value="image2RotationY"
        v-on:input="rotateY"
      />
    </div>
    <div class="input">
      <h4>Rotation Z</h4>
      <input
        type="range"
        min="0"
        max="360"
        v-bind:value="image2RotationZ"
        v-on:input="rotateZ"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "UiImage2",
  methods: {
    toggleModalImages() {
      this.$store.commit("toggleModalImages");
      this.$store.commit("setImageTarget", 2);
    },
    scaleImage(e) {
      this.$store.commit("scaleImage2", e.target.value);
    },
    rotateX(e) {
      this.$store.commit("rotateImage2X", e.target.value);
    },
    rotateY(e) {
      this.$store.commit("rotateImage2Y", e.target.value);
    },
    rotateZ(e) {
      this.$store.commit("rotateImage2Z", e.target.value);
    },
  },
  computed: {
    currentImage() {
      return this.$store.state.layout.image2.url;
    },
    imageScale() {
      return this.$store.state.layout.image2.scale;
    },
    image2RotationX() {
      return this.$store.state.layout.image2.rotate.x;
    },
    image2RotationY() {
      return this.$store.state.layout.image2.rotate.y;
    },
    image2RotationZ() {
      return this.$store.state.layout.image2.rotate.z;
    },
  },
};
</script>
