<template>
  <div id="uiHeadline2">
    <div class="input-textarea">
      <textArea
        rows="3"
        :placeholder="headline2"
        v-model="headline2"
        v-on:input="updateHeadline2"
      />
    </div>
    <div class="input">
      <h4>Größe</h4>
      <input
        type="range"
        min="50"
        max="600"
        v-bind:value="headline2Size"
        v-on:input="updateHeadline2Size"
      />
    </div>
    <div class="input">
      <h4>Linienhöhe</h4>
      <input
        type="range"
        min="0"
        max="200"
        step="1"
        v-bind:value="headline2LineHeight"
        v-on:input="updateHeadline2LineHeight"
      />
    </div>
    <div class="input">
      <h4>Rotation X</h4>
      <input
        type="range"
        min="0"
        max="360"
        v-bind:value="headline2RotationX"
        v-on:input="rotateX"
      />
    </div>
    <div class="input">
      <h4>Rotation Y</h4>
      <input
        type="range"
        min="0"
        max="360"
        v-bind:value="headline2RotationY"
        v-on:input="rotateY"
      />
    </div>
    <div class="input">
      <h4>Rotation Z</h4>
      <input
        type="range"
        min="0"
        max="360"
        v-bind:value="headline2RotationZ"
        v-on:input="rotateZ"
      />
    </div>
    <div class="input">
      <h4>Farbe</h4>
      <div class="colors">
        <div
          class="color"
          @click="updateHeadline2Color(color)"
          v-for="(color, index) in colors"
          v-bind:key="index"
          v-bind:style="{ backgroundColor: color }"
          ref="headline2Color"
        ></div>
      </div>
    </div>

    <div class="input">
      <h4>Ausrichtung</h4>
      <TextAlign typoSelection="headline2" />
    </div>

    <div class="input input-lessRound">
      <h4>Schrift wählen</h4>
      <div class="imageSelectWrapper" v-on:click="toggleModalFonts()">
        <!-- <img v-bind:src="currentImage" /> -->
        <!-- <div class="imageSelectOverlay">
                    Select
                </div> -->
        Auswählen
      </div>
    </div>
  </div>
</template>

<script>
import TextAlign from "@/components/ui/TextAlign.vue";

export default {
  name: "UiHeadline2",
  components: {
    TextAlign,
  },
  mounted() {
    this.updateHeadline2UI();
  },
  computed: {
    headline2Size() {
      return this.$store.state.layout.headline2.size;
    },
    headline2() {
      return this.$store.state.layout.headline2.text;
    },
    headline2LineHeight() {
      return this.$store.state.layout.headline2.lineHeight;
    },
    colors() {
      return this.$store.state.colors;
    },
    headline2RotationX() {
      return this.$store.state.layout.headline2.rotate.x;
    },
    headline2RotationY() {
      return this.$store.state.layout.headline2.rotate.y;
    },
    headline2RotationZ() {
      return this.$store.state.layout.headline2.rotate.z;
    },
  },
  methods: {
    rotateX(e) {
      this.$store.commit("rotateHeadline2X", e.target.value);
    },
    rotateY(e) {
      this.$store.commit("rotateHeadline2Y", e.target.value);
    },
    rotateZ(e) {
      this.$store.commit("rotateHeadline2Z", e.target.value);
    },
    updateHeadline2Size(e) {
      this.$store.commit("changeHeadline2Size", e.target.value);
    },
    updateHeadline2LineHeight(e) {
      this.$store.commit("changeHeadline2LineHeight", e.target.value);
    },
    updateHeadline2(e) {
      this.$store.commit("changeHeadline2", e.target.value);
    },
    updateHeadline2Color(color) {
      this.$store.commit("changeHeadline2Color", color);
      this.updateHeadline2UI();
    },
    toggleModalFonts() {
      this.$store.commit("toggleModalFonts");
      this.$store.commit("setFontsTarget", 2);
    },
    updateHeadline2UI() {
      //holt alle Farben vom UI ab und cleared die selected Farbe
      let colors = this.$refs.headline2Color;
      colors.forEach((color) => {
        color.classList.remove("selectedColor");
      });

      // vergleicht, was die selektierte Farbe ist und fügt "selectedHeadLine1" als CSS Klasse hinzu
      let selectedColor = colors.filter(
        (color) =>
          this.rgb2hex(color.style.backgroundColor) ==
          this.$store.state.layout.headline2.color
      );
      selectedColor[0].classList.add("selectedColor");
    },
    rgb2hex(rgb) {
      // konvertiert Farben von RGB in HEX.
      return `#${rgb
        .match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
        .slice(1)
        .map((n) => parseInt(n, 10).toString(16).toUpperCase().padStart(2, "0"))
        .join("")}`;
    },
  },
};
</script>

<style>
</style>