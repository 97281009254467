<template>
  <div id="artboard">
    <div id="canvasWrapper">
      <Canvas />
    </div>
    <div class="pageIndicator" v-if="isCover">
      <h3>Hinterseite</h3>
      <h3>Vorderseite</h3>
    </div>
  </div>
</template>

<script>
import Canvas from "./Canvas.vue";

export default {
  name: "Artboard",
  components: {
    Canvas,
  },
  computed: {
    isCover() {
      return this.$store.state.layout.isCover;
    },
  },
};
</script>

<style>
#artboard {
  width: 100%;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#canvasWrapper {
  margin-left: 500px;
  width: 820px;
  height: 594px;
  background: #f1f1f1;
  position: relative;
  cursor: grabbing;
}

.pageIndicator {
  margin-left: 500px;
  margin-top: 10px;
  width: 820px;
  display: flex;
  justify-content: space-around;
}

.pageIndicator h3 {
  color: var(--gray-500__digizine);
  font-size: 13px;
  text-align: center;
  width: 50%;
  text-transform: uppercase;
}

#canvasWrapper canvas {
  width: 820px !important;
  height: 594px !important;
}

@media (min-width: 1600px) {
  #canvasWrapper {
  }
}
</style>
