<template>
  <div id="ui-outer-wrapper">
    <div id="ui-wrapper">
      <!-- <a @click="toggleSpreadView" class="shadow ui-btn" id="btn-submit">
                <h2>Das Magazin</h2>
            </a> -->
      <div id="ui" class="shadow">
        <a style="cursor: pointer" @click="toggleToolbox">
          <h2
            style="
              margin-top: 0;
              margin-bottom: 0px;
              padding: 0;
              font-size: 33px;
            "
          >
            Werkzeuge
          </h2>
        </a>
        <div id="toolbox" v-if="showToolbox">
          <div style="margin-top: 15px">
            <LayerList />
          </div>
          <hr />
          <transition name="ui" mode="out-in">
            <UiBasis v-if="activeUi == 'BASIS'" key="basis" />
            <UiHeadline1 v-if="activeUi == 'HEADLINE1'" key="headline1" />
            <UiHeadline2 v-if="activeUi == 'HEADLINE2'" key="headline2" />
            <UiText v-if="activeUi == 'TEXT'" key="text" />
            <UiImage1 v-if="activeUi == 'IMAGE1'" key="image1" />
            <UiImage2 v-if="activeUi == 'IMAGE2'" key="image2" />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiHeadline1 from "./ui/Headline1.vue";
import UiHeadline2 from "./ui/Headline2.vue";
import UiText from "./ui/Text.vue";
import UiBasis from "./ui/Basis.vue";
import LayerList from "./ui/LayerList.vue";
import UiImage1 from "./ui/Image1.vue";
import UiImage2 from "./ui/Image2.vue";

export default {
  name: "Ui",
  components: {
    LayerList,
    UiHeadline1,
    UiHeadline2,
    UiImage1,
    UiImage2,
    UiBasis,
    UiText,
  },
  computed: {
    activeUi() {
      return this.$store.state.ui.activeUi;
    },
    showToolbox() {
      return this.$store.state.ui.showToolbox;
    },
  },
  methods: {
    switchUi(e) {
      this.$store.commit("switchUi", e);
    },
    toggleToolbox() {
      this.$store.commit("toggleToolbox");
    },
    toggleSpreadView() {
      this.$store.commit("toggleModalSpreads");
    },
  },
};
</script>

<style>
.ui-btn {
  display: block;
  padding: 10px 15px 5px 15px;
  text-transform: uppercase;
  margin-bottom: 15px;
  background: var(--green);
  cursor: pointer;
}

.ui-btn h2 {
  font-weight: 900;
  margin: 0;
  color: white;
}

#btn-submit {
}

#ui h2 {
  text-transform: uppercase;
  padding: 10px 0;
}

#toolbox {
  height: 600px;
  overflow-y: auto;
  margin-top: 10px;
  padding-right: 7.5px;
}

#ui-wrapper {
  width: inherit;
}

#ui-outer-wrapper {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 9999;
  width: 450px;
  display: grid;
  grid-template-columns: 50px 400px;
  grid-gap: 10px;
}

#ui {
  background: white;
  padding: 15px 7.5px 15px 15px;
}

.shadow {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

/* .ui__lowerContainer {
  max-height: 370px;
  overflow-y: auto;
} */

#ui * {
  color: var(--green);
}

.input {
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-columns: 1fr 2fr;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 30px;
  border: 1px solid var(--gray-100__digizine);
  transition: border 0.1s ease;
  outline: none;
}

.input:hover {
  border: 1px solid var(--gray-700__digizine);
}

::placeholder {
  font-family: "AntarcticaBeta";
  color: var(--green-500__digizine);
}

.input h4 {
  margin-bottom: 0;
  font-size: 15px;
}

.input input,
.input textarea {
  width: 100%;
  resize: none;
  font-family: "AntarcticaBeta";
}

.imageSelectWrapper {
  position: relative;
  width: 100%;
  height: 100px;
  background: var(--green-100__digizine);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.imageSelectWrapper--Image {
  width: 310%;
  height: 130px;
}

.imageSelectWrapper:hover {
  background: var(--green-500__digizine);
}

.imageSelectWrapper svg {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
}

.imageSelectWrapper:hover .imageSelectOverlay {
  opacity: 1;
}

.imageSelectOverlay {
  transition: opacity 0.2s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00ff00;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  cursor: pointer;
}

.color {
  width: 17px;
  height: 17px;
  border-radius: 100%;
  transition: all 0.5s ease-in-out;
  position: relative;
}

.color:hover:after {
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: transparent;
  border: 1px solid white;
  border-radius: 100%;
  content: "";
}

.colors {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}

#back-btn {
  width: 45px;
  height: 45px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
  padding: none;
}

#back-btn svg {
  width: 100%;
  height: auto;
}

#back-btn svg:hover {
  background: white !important;
  stroke: var(--green-700__digizine) !important;
}

hr {
  background: var(--green);
  height: 1px;
  border: 0;
  margin-top: 25px;
  margin-bottom: 40px;
}

.ui-enter-from,
.ui-leave-to {
  opacity: 0;
}

.ui-enter-to {
  opacity: 1;
}

.ui-enter-active,
.ui-leave-active {
  transition: all 0.4s ease-in-out;
}
</style>
