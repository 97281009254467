<template>
  <div>
    <div class="input-textarea">
      <textArea
        rows="5"
        :placeholder="this.$store.state.layout.text.text"
        v-model="text"
        v-on:input="updateText"
      />
    </div>

    <div class="input">
      <h4>Farbe</h4>
      <div class="colors">
        <div
          class="color"
          @click="updateTextColor(color)"
          v-for="(color, index) in colors"
          v-bind:key="index"
          v-bind:style="{ backgroundColor: color }"
          ref="textColor"
        ></div>
      </div>
    </div>
    <div class="input">
      <h4>Größe</h4>
      <input
        type="range"
        min="20"
        max="100"
        v-bind:value="textSize"
        v-on:input="updateTextSize"
      />
    </div>
    <div class="input">
      <h4>Linienhöhe</h4>
      <input
        type="range"
        min="0"
        max="2"
        step="0.01"
        v-bind:value="lineHeight"
        v-on:input="updateTextLineHeight"
      />
    </div>
    <div class="input">
      <h4>Breite</h4>
      <input
        type="range"
        min="50"
        max="2000"
        v-bind:value="textWidth"
        v-on:input="updateTextWidth"
      />
    </div>
    <div class="input">
      <h4>Ausrichtung</h4>
      <TextAlign typoSelection="text" />
    </div>
  </div>
</template>

<script>
import TextAlign from "@/components/ui/TextAlign.vue";

export default {
  name: "UiText",
  components: {
    TextAlign,
  },
  mounted() {
    this.updateTextColorUI();
  },
  methods: {
    updateText(e) {
      this.$store.commit("changeText", e.target.value);
    },
    updateTextColor(color) {
      this.$store.commit("updateTextColor", color);
      this.updateTextColorUI();
    },
    updateTextSize(e) {
      this.$store.commit("updateTextSize", e.target.value);
    },
    updateTextLineHeight(e) {
      this.$store.commit("updateTextLineHeight", e.target.value);
    },
    updateTextWidth(e) {
      this.$store.commit("updateTextWidth", e.target.value);
    },
    updateTextColorUI() {
      //holt alle Farben vom UI ab und cleared die selected Farbe
      let colors = this.$refs.textColor;
      colors.forEach((color) => {
        color.classList.remove("selectedColor");
      });

      // vergleicht, was die selektierte Farbe ist und fügt "selectedHeadLine1" als CSS Klasse hinzu
      let selectedColor = colors.filter(
        (color) =>
          this.rgb2hex(color.style.backgroundColor) ==
          this.$store.state.layout.text.color
      );
      selectedColor[0].classList.add("selectedColor");
    },
    rgb2hex(rgb) {
      // konvertiert Farben von RGB in HEX.
      return `#${rgb
        .match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
        .slice(1)
        .map((n) => parseInt(n, 10).toString(16).toUpperCase().padStart(2, "0"))
        .join("")}`;
    },
  },
  computed: {
    text() {
      return this.$store.state.layout.text.text;
    },
    colors() {
      return this.$store.state.colors;
    },
    textSize() {
      return this.$store.state.layout.text.size;
    },
    lineHeight() {
      return this.$store.state.layout.text.lineHeight;
    },
    textWidth() {
      return this.$store.state.layout.text.width;
    },
  },
};
</script>
