<template>
  <div>
    <vue-p5
      @mousepressed="mousePressed"
      @mousereleased="mouseReleased"
      @preload="preload"
      @setup="setup"
      @draw="draw"
      v-once
    >
    </vue-p5>
    <div id="magazineOverlay"></div>
  </div>
</template>

<script>
import VueP5 from "vue-p5";

export default {
  name: "p5-example",
  data: () => ({
    font: null,
    textFont: null,
    image1: null,
    image2: null,
    offsetX: 0,
    offsetY: 0,
    currentPos: {
      x: 0,
      y: 0,
    },
  }),
  components: {
    "vue-p5": VueP5,
  },
  methods: {
    preload(c) {
      this.image1 = c.loadImage(this.layout.image1.url);
      this.image2 = c.loadImage(this.layout.image2.url);
      this.fontHeadline1 = c.loadFont("fonts/AntarcticaBeta-ExpExtBlk.otf");
      this.fontHeadline2 = c.loadFont("fonts/AntarcticaBeta-ExpExtBlk.otf");
      this.textFont = c.loadFont("fonts/AntarcticaBeta-Regular.otf");
    },
    setup(c) {
      const wrapper = document.getElementById("canvasWrapper");
      c.createCanvas(wrapper.offsetWidth * 2, wrapper.offsetHeight * 2, c.P2D);
      c.imageMode(c.CENTER);
      c.dragging = false;

      c.pgImage1 = c.createGraphics(c.width, c.height, c.WEBGL);
      c.pgImage2 = c.createGraphics(c.width, c.height, c.WEBGL);
      c.pgHeadline1 = c.createGraphics(c.width, c.height, c.WEBGL);
      c.pgHeadline2 = c.createGraphics(c.width, c.height, c.WEBGL);
    },
    draw(c) {
      ////////////////////////////////////////////////////////
      // Update Image1
      ////////////////////////////////////////////////////////
      if (this.layout.image1.update == true) {
        this.image1 = c.loadImage(this.layout.image1.url);
        this.$store.commit("updateImage1False");
      }

      ////////////////////////////////////////////////////////
      // Update Image2
      ////////////////////////////////////////////////////////
      if (this.layout.image2.update == true) {
        this.image2 = c.loadImage(this.layout.image2.url);
        this.$store.commit("updateImage2False");
      }

      ////////////////////////////////////////////////////////
      // Update Headline1 font
      ////////////////////////////////////////////////////////
      /*       if (this.layout.headline1.update == true) {
        this.fontHeadline1 = c.loadFont(this.layout.headline1.font);
        this.fontHeadline1 = c.loadFont(this.layout.headline1.font);
        this.$store.commit("updateHeadline1Font");
      } */

      c.background(this.layout.base.background);

      ////////////////////////////////////////////////////////
      // Image1
      ////////////////////////////////////////////////////////
      if (this.layout.image1.visible) {
        // pgGraphics for image1
        c.pgImage1.imageMode(c.CENTER);
        c.pgImage1.clear();
        c.pgImage1.push();
        c.pgImage1.translate(
          -c.width / 2 + this.layout.image1.pos.x,
          -c.height / 2 + this.layout.image1.pos.y
        );
        c.pgImage1.scale(this.layout.image1.scale / 100);
        c.pgImage1.rotateX(c.radians(this.layout.image1.rotate.x));
        c.pgImage1.rotateY(c.radians(this.layout.image1.rotate.y));
        c.pgImage1.rotateZ(c.radians(this.layout.image1.rotate.z));
        c.pgImage1.image(this.image1, 0, 0);
        c.pgImage1.pop();

        c.push();
        c.translate(c.width / 2, c.height / 2);
        c.image(c.pgImage1, 0, 0);
        c.pop();
      }

      ////////////////////////////////////////////////////////
      // Image2
      ////////////////////////////////////////////////////////
      if (this.layout.image2.visible) {
        // pgGraphics for image1
        c.pgImage2.clear();
        c.pgImage2.imageMode(c.CENTER);

        c.pgImage2.push();
        c.pgImage2.translate(
          -c.width / 2 + this.layout.image2.pos.x,
          -c.height / 2 + this.layout.image2.pos.y
        );
        c.pgImage2.rotateX(c.radians(this.layout.image2.rotate.x));
        c.pgImage2.rotateY(c.radians(this.layout.image2.rotate.y));
        c.pgImage2.rotateZ(c.radians(this.layout.image2.rotate.z));
        c.pgImage2.scale(this.layout.image2.scale / 100);
        c.pgImage2.image(this.image2, 0, 0);
        c.pgImage2.pop();

        c.push();
        c.translate(c.width / 2, c.height / 2);
        c.image(c.pgImage2, 0, 0);
        c.pop();
      }

      ////////////////////////////////////////////////////////
      // Headline 1
      ////////////////////////////////////////////////////////

      if (this.layout.headline1.visible) {
        c.pgHeadline1.clear();
        c.pgHeadline1.fill(this.layout.headline1.color);
        c.pgHeadline1.noStroke();
        c.pgHeadline1.textFont(this.fontHeadline1);

        // change headline 1 font
        if (this.layout.headline1.update) {
          this.fontHeadline1 = c.loadFont(this.layout.headline1.font);
          this.$store.commit("updateHeadline1Font");
        }

        c.pgHeadline1.push();
        c.pgHeadline1.translate(
          -c.width / 2 + this.layout.headline1.pos.x,
          -c.height / 2 + this.layout.headline1.pos.y
        );

        // change headline1 text alignment
        let alignmentHeadline1 = this.layout.headline1.align;
        if (alignmentHeadline1 == "LEFT") {
          c.pgHeadline1.textAlign(c.LEFT, c.CENTER);
        }
        if (alignmentHeadline1 == "CENTER") {
          c.pgHeadline1.textAlign(c.CENTER, c.CENTER);
        }
        if (alignmentHeadline1 == "RIGHT") {
          c.pgHeadline1.textAlign(c.RIGHT, c.CENTER);
        }

        c.pgHeadline1.textSize(this.layout.headline1.size);
        c.pgHeadline1.textLeading(
          this.layout.headline1.size * (this.layout.headline1.lineHeight / 100)
        );

        c.pgHeadline1.rotateX(c.radians(this.layout.headline1.rotate.x));
        c.pgHeadline1.rotateY(c.radians(this.layout.headline1.rotate.y));
        c.pgHeadline1.rotateZ(c.radians(this.layout.headline1.rotate.z));
        c.pgHeadline1.text(this.layout.headline1.text, 0, 0);
        c.pgHeadline1.pop();

        c.push();
        c.translate(c.width / 2, c.height / 2);
        c.image(c.pgHeadline1, 0, 0);
        c.pop();
      }

      ////////////////////////////////////////////////////////
      // Headline 2
      ////////////////////////////////////////////////////////

      if (this.layout.headline2.visible) {
        c.pgHeadline2.clear();
        c.pgHeadline2.fill(this.layout.headline2.color);
        c.pgHeadline2.noStroke();
        c.pgHeadline2.textFont(this.fontHeadline2);

        // change headline 2 font
        if (this.layout.headline2.update) {
          this.fontHeadline2 = c.loadFont(this.layout.headline2.font);
          this.$store.commit("updateHeadline2Font");
        }

        c.pgHeadline2.textSize(this.layout.headline2.size);
        c.pgHeadline2.textLeading(
          this.layout.headline2.size * (this.layout.headline2.lineHeight / 100)
        );
        c.pgHeadline2.push();

        c.pgHeadline2.translate(
          -c.width / 2 + this.layout.headline2.pos.x,
          -c.height / 2 + this.layout.headline2.pos.y
        );

        // change headline 2 alignment
        let alignmentHeadline2 = this.layout.headline2.align;
        if (alignmentHeadline2 == "LEFT") {
          c.pgHeadline2.textAlign(c.LEFT, c.CENTER);
        }
        if (alignmentHeadline2 == "CENTER") {
          c.pgHeadline2.textAlign(c.CENTER, c.CENTER);
        }
        if (alignmentHeadline2 == "RIGHT") {
          c.pgHeadline2.textAlign(c.RIGHT, c.CENTER);
        }

        c.pgHeadline2.rotateX(c.radians(this.layout.headline2.rotate.x));
        c.pgHeadline2.rotateY(c.radians(this.layout.headline2.rotate.y));
        c.pgHeadline2.rotateZ(c.radians(this.layout.headline2.rotate.z));
        c.pgHeadline2.text(this.layout.headline2.text, 0, 0);
        c.pgHeadline2.pop();

        c.push();
        c.translate(c.width / 2, c.height / 2);
        c.image(c.pgHeadline2, 0, 0);
        c.pop();
      }

      ////////////////////////////////////////////////////////
      // Fliesstext
      ////////////////////////////////////////////////////////
      if (this.layout.text.visible) {
        c.fill(this.layout.text.color);
        c.noStroke();
        c.textFont(this.textFont);

        let alignmentText = this.layout.text.align;
        if (alignmentText == "LEFT") {
          c.textAlign(c.LEFT, c.TOP);
        }
        if (alignmentText == "CENTER") {
          c.textAlign(c.CENTER, c.TOP);
        }
        if (alignmentText == "RIGHT") {
          c.textAlign(c.RIGHT, c.TOP);
        }

        c.textSize(Math.floor(this.layout.text.size));
        c.textLeading(
          Math.floor(this.layout.text.lineHeight * this.layout.text.size)
        );
        c.push();
        c.translate(this.layout.text.pos.x, this.layout.text.pos.y);
        c.text(this.layout.text.text, 0, 0, this.layout.text.width);
        c.pop();
      }

      ////////////////////////////////////////////////////////
      // DRAGGING
      ////////////////////////////////////////////////////////

      if (c.dragging) {
        var newpos;

        newpos = {
          x: Math.floor(c.mouseX - this.offsetX),
          y: Math.floor(c.mouseY - this.offsetY),
        };

        if (this.activeUi == "HEADLINE1") {
          this.repositionHeadline1(newpos);
        } else if (this.activeUi == "HEADLINE2") {
          this.repositionHeadline2(newpos);
        } else if (this.activeUi == "IMAGE1") {
          this.repositionImage1(newpos);
        } else if (this.activeUi == "IMAGE2") {
          this.repositionImage2(newpos);
        } else if (this.activeUi == "TEXT") {
          this.repositionText(newpos);
        }
      }

      ////////////////////////////////////////////////////////
      // Save Image
      ////////////////////////////////////////////////////////

      if (this.saveImage == true) {
        c.saveCanvas("layout.jpg");
        this.$store.commit("saveImageFalse");
      }
    },

    ////////////////////////////////////////////////////////
    // Mouse Dragging
    ////////////////////////////////////////////////////////

    mouseReleased(c) {
      // Quit dragging
      c.dragging = false;
    },
    mousePressed(c) {
      if (
        c.mouseX > 0 &&
        c.mouseX < c.width &&
        c.mouseY > 0 &&
        c.mouseY < c.height
      ) {
        c.dragging = true;
      }

      if (this.activeUi == "HEADLINE1") {
        this.currentPos.x = this.layout.headline1.pos.x;
        this.currentPos.y = this.layout.headline1.pos.y;
      } else if (this.activeUi == "HEADLINE2") {
        this.currentPos.x = this.layout.headline2.pos.x;
        this.currentPos.y = this.layout.headline2.pos.y;
      } else if (this.activeUi == "IMAGE1") {
        this.currentPos.x = this.layout.image1.pos.x;
        this.currentPos.y = this.layout.image1.pos.y;
      } else if (this.activeUi == "IMAGE2") {
        this.currentPos.x = this.layout.image2.pos.x;
        this.currentPos.y = this.layout.image2.pos.y;
      } else if (this.activeUi == "TEXT") {
        this.currentPos.x = this.layout.text.pos.x;
        this.currentPos.y = this.layout.text.pos.y;
      }

      this.offsetX = c.mouseX - this.currentPos.x;
      this.offsetY = c.mouseY - this.currentPos.y;
    },
    repositionHeadline1(newpos) {
      this.$store.commit("repositionHeadline1", newpos);
    },
    repositionHeadline2(newpos) {
      this.$store.commit("repositionHeadline2", newpos);
    },
    repositionImage1(newpos) {
      this.$store.commit("repositionImage1", newpos);
    },
    repositionImage2(newpos) {
      this.$store.commit("repositionImage2", newpos);
    },
    repositionText(newpos) {
      this.$store.commit("repositionText", newpos);
    },
  },
  computed: {
    activeUi() {
      return this.$store.state.ui.activeUi;
    },

    saveImage() {
      return this.$store.state.saveImage;
    },

    layout() {
      return this.$store.state.layout;
    },
  },
};
</script>

<style scoped>
#magazineOverlay {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 0.2) 100%
  );

  width: 50%;
  height: 100%;
  z-index: 999;
}
</style>
