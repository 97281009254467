<template>
  <div id="uiLayers">
    <div class="input input-lessRound noBorder" style="padding-bottom: 0">
      <h4>Ebenen</h4>
      <div id="layerList">
        <div class="layer" v-bind:class="activeUi == 'BASIS' ? 'active' : ''">
          <div class="wrapper">
            <div class="layerName" v-on:click="switchUi('BASIS')">
              Hintergrund
            </div>
          </div>
        </div>
        <div
          class="layer"
          v-bind:class="activeUi == 'HEADLINE1' ? 'active' : ''"
        >
          <div class="wrapper">
            <div class="lamp" @click="toggleHeadline1Visibility">
              <img
                v-bind:class="isHeadline1Visible == true ? 'isVisible' : ''"
                src="@/assets/check.svg"
                alt=""
              />
            </div>

            <div class="layerName" v-on:click="switchUi('HEADLINE1')">
              Überschrift 1
            </div>
          </div>
        </div>

        <div
          class="layer"
          v-bind:class="activeUi == 'HEADLINE2' ? 'active' : ''"
        >
          <div class="wrapper">
            <div class="lamp" @click="toggleHeadline2Visibility">
              <img
                v-bind:class="isHeadline2Visible == true ? 'isVisible' : ''"
                src="@/assets/check.svg"
                alt=""
              />
            </div>

            <div class="layerName" v-on:click="switchUi('HEADLINE2')">
              Überschrift 2
            </div>
          </div>
        </div>

        <div class="layer" v-bind:class="activeUi == 'TEXT' ? 'active' : ''">
          <div class="wrapper">
            <div class="lamp" @click="toggleTextVisibility">
              <img
                v-bind:class="isTextVisible == true ? 'isVisible' : ''"
                src="@/assets/check.svg"
                alt=""
              />
            </div>

            <div class="layerName" v-on:click="switchUi('TEXT')">Text</div>
          </div>
        </div>

        <div class="layer" v-bind:class="activeUi == 'IMAGE1' ? 'active' : ''">
          <div class="wrapper">
            <div class="lamp" @click="toggleImage1Visibility">
              <img
                v-bind:class="isImage1Visible == true ? 'isVisible' : ''"
                src="@/assets/check.svg"
                alt=""
              />
            </div>

            <div class="layerName" v-on:click="switchUi('IMAGE1')">Bild 1</div>
          </div>
        </div>

        <div class="layer" v-bind:class="activeUi == 'IMAGE2' ? 'active' : ''">
          <div class="wrapper">
            <div class="lamp" @click="toggleImage2Visibility">
              <img
                v-bind:class="isImage2Visible == true ? 'isVisible' : ''"
                src="@/assets/check.svg"
                alt=""
              />
            </div>

            <div class="layerName" v-on:click="switchUi('IMAGE2')">Bild 2</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayerList",
  methods: {
    switchUi(e) {
      this.$store.commit("switchUi", e);
    },
    toggleHeadline1Visibility() {
      this.$store.commit("toggleHeadline1Visibility");
    },
    toggleHeadline2Visibility() {
      this.$store.commit("toggleHeadline2Visibility");
    },
    toggleTextVisibility() {
      this.$store.commit("toggleTextVisibility");
    },
    toggleImage1Visibility() {
      this.$store.commit("toggleImage1Visibility");
    },
    toggleImage2Visibility() {
      this.$store.commit("toggleImage2Visibility");
    },
  },
  computed: {
    activeUi() {
      return this.$store.state.ui.activeUi;
    },
    isHeadline1Visible() {
      return this.$store.state.layout.headline1.visible;
    },
    isHeadline2Visible() {
      return this.$store.state.layout.headline2.visible;
    },
    isTextVisible() {
      return this.$store.state.layout.text.visible;
    },
    isImage1Visible() {
      return this.$store.state.layout.image1.visible;
    },
    isImage2Visible() {
      return this.$store.state.layout.image2.visible;
    },
  },
};
</script>

<style>
#layerList {
  margin: 0;
  list-style: none;
  cursor: pointer;
}

#layerList .layer {
  color: var(--green);
  margin-bottom: 5px;
}

#layerList .layer:last-child {
}

.wrapper {
  display: inline-flex;
  border-radius: 15px;
  padding: 3px;
  background: var(--gray-100__digizine);
}

.wrapper:hover {
  background: var(--green-500__digizine);
}

#layerList .layer.active .wrapper {
  color: white;
  background: var(--green);
}

#layerList .layer.active .wrapper .layerName {
  color: white;
}

.lamp {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  background: white;
  border-radius: 50%;
}

.lamp img {
  opacity: 0;
  width: 15px !important;
  height: auto !important;
  object-fit: contain !important;
  transition: opacity 0.25s ease;
}

.isVisible {
  opacity: 1 !important;
}

.layerName {
  margin-right: 5px;
  margin-left: 5px;
  width: 100%;
  font-size: 15px;
  color: white !important;
  font-weight: lighter;
}

.input-lessRound {
  border-radius: 15px !important;
}

.noBorder {
  border: none !important;
  align-items: start !important;
  padding-left: 0 !important;
}

.noBorder h4 {
  font-size: 19px;
}
</style>
